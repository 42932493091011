import {
  TransType,
  UseTranslation,
} from "@sellernote/_shared/src/types/common/i18n";

import Styled from "./index.styles";

/**
 * i18n을 지원하는 컴포넌트 sample.
 *
 * 사용하는 측에서 i18n관련 인스턴스/힘수(Trans, useTranslation 등) 를 전달해서 사용한다.
 */
export default function SampleWithI18n({
  Trans,
  useTranslation,
  className,
}: {
  Trans: TransType;
  useTranslation: UseTranslation;
  className?: string;
}) {
  const { t } = useTranslation(["common"]);

  return (
    <Styled.container
      className={`${className ? className : ""} sample-with-i18n`}
    >
      SampleWithI18n
      <p>{t("common:login")}</p>
      <p>
        <Trans i18nKey="common:confirm" />
      </p>
    </Styled.container>
  );
}
