import * as shipdaAdminOrderHandlers from "../shipda-admin-api/order/handlers";
import * as shipdaAdminPromotionHandlers from "../shipda-admin-api/promotion/handlers";
import * as shipdaAdminTrelloBidHandlers from "../shipda-admin-api/trello/handlers";
import * as shipdaAdminBidUserHandlers from "../shipda-admin-api/user/handlers";

export default {
  ...shipdaAdminTrelloBidHandlers,
  ...shipdaAdminPromotionHandlers,
  ...shipdaAdminBidUserHandlers,
  ...shipdaAdminOrderHandlers,
};
