import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import Router from "Router";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";

const simulateBrowserScan = (barcode: string) => {
  barcode.split("").forEach((v) => {
    document.dispatchEvent(
      new KeyboardEvent("keypress", {
        key: v,
      })
    );
  });
};

function App() {
  const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

  useEffect(() => {
    if (!IS_UNDER_PRODUCTION)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).simulateBrowserScan = simulateBrowserScan;
  }, []);

  return (
    <>
      <RecoilRoot>
        {IS_READY_FOR_MSW_TOOL && (
          <MSWTool Table={Table} TabFilter={TabFilter} type="web" />
        )}

        <QueryClientProvider client={queryClient}>
          {IS_UNDER_LOCAL_DEVELOPMENT && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <Router>
            <GlobalStyle />
          </Router>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
