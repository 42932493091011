import React from "react";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { APP_TYPE } from "../../constants";
import DefaultLoadingGif from "./loading.gif";
import BofulLoadingLogo from "./loading_logo_boful.png";
import Styled from "./index.styles";

function DefaultLoading({
  label,
  active,
  className,
}: {
  active: boolean;
  label?: React.ReactNode;
  className?: string;
}) {
  if (!active) {
    return null;
  }
  return (
    <Styled.defaultContainer
      active={active}
      className={`${className ? className : ""} loading`}
    >
      <Styled.defaultImageWrapper>
        <img src={DefaultLoadingGif} alt="loading" />
      </Styled.defaultImageWrapper>

      <div className="label">{label}</div>
    </Styled.defaultContainer>
  );
}

function BofulLoading({
  active,
  className,
  label,
}: {
  active: boolean;
  className?: string;
  label?: React.ReactNode;
}) {
  if (!active) {
    return null;
  }
  return (
    <Styled.bofulContainer
      active={active}
      className={`${className ? className : ""} loading loading-boful`}
    >
      <img src={BofulLoadingLogo} alt="loading" />

      <Styled.bofulLoader />
      {/* TODO: API 순차 호출 시 병목점이 어디인지 확인을 위하여 임시 라벨을 추가함 */}
      <div style={{ color: COLOR.white, marginTop: 20 }}>{label}</div>
    </Styled.bofulContainer>
  );
}

export default function Loading({
  label,
  active,
  className,
}: {
  active: boolean;
  label?: React.ReactNode;
  /**
   * boful만 로딩바가 달라서 이렇게 처리함.
   */
  className?: string;
}) {
  if (!active) {
    return null;
  }
  return APP_TYPE === "Boful" || APP_TYPE === "BofulMobile" ? (
    <BofulLoading active={active} label={label} className={className} />
  ) : (
    <DefaultLoading active={active} label={label} className={className} />
  );
}
