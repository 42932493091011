import React, { memo, useMemo } from "react";
import { useRecoilValue } from "recoil";

import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { InvoiceSummaryType } from "@sellernote/_shared/src/types/fulfillment/packing";
import { ShippingPacking } from "@sellernote/_shared/src/types/fulfillment/shipping";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import usePrintInvoice from "./usePrintInvoice";
import Styled from "./index.styles";

interface InvoiceToIssueItem {
  packingNo: number;
  invoiceNo: string;
  issueInvoice: React.ReactNode;
}

function InvoiceSummary({
  info,
  totalCount,
}: {
  info: InvoiceSummaryType;
  totalCount: number;
}) {
  return (
    <Styled.invoiceSummary>
      <div className="message">출력할 내용을 확인 후 송장 출력해주세요.</div>

      <div className="invoice-summary">
        <div>고객명(코드명): {`${info.userCompanyName}(${info.userId})`}</div>
        <div>출고요청번호: {info.shippingId}</div>
      </div>

      <div className="total-count">전체 송장 수: {totalCount}</div>
    </Styled.invoiceSummary>
  );
}

function InvoiceToIssueListTable({
  packings,
}: {
  packings: ShippingPacking[];
}) {
  const {
    printInvoice,

    ResponseHandlerOfGettingInvoicePrintingData,
    LoadingOfPrintingInvoice,
    ToastOfPrintingInvoice,
  } = usePrintInvoice();

  const tableDataList = useMemo(
    (): TableDataListItem<InvoiceToIssueItem>[] =>
      packings.map((packingItem, i) => ({
        rowKey: packingItem.id,

        packingNo: i + 1,

        invoiceNo: packingItem.invoiceNo,

        issueInvoice: (
          <TextButton
            theme="withIcon"
            size="14px"
            icon="arrowRight"
            label="출력"
            handleClick={printInvoice({
              packingIds: [packingItem.id],
              invoiceNo: packingItem.invoiceNo,
            })}
          />
        ),
      })),
    [packings, printInvoice]
  );

  return (
    <>
      <Styled.printInvoiceTableWrapper
        isVisibleScrollbar={tableDataList.length > 5}
      >
        <Table<InvoiceToIssueItem>
          columnInfo={{
            packingNo: {
              label: "포장번호",
            },
            invoiceNo: {
              label: "송장(QR)번호",
              portion: 2,
            },
            issueInvoice: {
              label: "출력",
            },
          }}
          dataList={tableDataList}
        />
      </Styled.printInvoiceTableWrapper>

      {ResponseHandlerOfGettingInvoicePrintingData}
      {LoadingOfPrintingInvoice}
      {ToastOfPrintingInvoice}
    </>
  );
}

function ActionButtons({ packings }: { packings: ShippingPacking[] }) {
  const packingIds = packings.map((packing) => packing.id);

  const {
    printInvoice,

    ResponseHandlerOfGettingInvoicePrintingData,
    LoadingOfPrintingInvoice,
    ToastOfPrintingInvoice,
  } = usePrintInvoice(packingIds);

  return (
    <>
      <Styled.actionButtons>
        <div className="barcode-button">
          <Barcode
            value="PRI_ALL_INV"
            options={{
              displayValue: false,
              margin: 0,
            }}
          />
          <Button
            theme="primary"
            size="block"
            label="전체 송장 출력"
            handleClick={printInvoice({ packingIds })}
          />
        </div>
      </Styled.actionButtons>

      {ResponseHandlerOfGettingInvoicePrintingData}
      {LoadingOfPrintingInvoice}
      {ToastOfPrintingInvoice}
    </>
  );
}

function PrintInvoiceModal({
  active,
  onClose,
}: {
  active: boolean;
  onClose: () => void;
}) {
  const invoiceSummary = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const packings = useRecoilValue(FULFILLMENT_PACKING_ATOMS.PACKINGS);

  return (
    <Modal
      uiType="contentWithCustomBody"
      active={active}
      title="송장 출력"
      body={
        <>
          <InvoiceSummary info={invoiceSummary} totalCount={packings.length} />
          <InvoiceToIssueListTable packings={packings} />
          <ActionButtons packings={packings} />
        </>
      }
      onClose={onClose}
    />
  );
}

export default memo(PrintInvoiceModal);
