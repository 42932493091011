import { memo } from "react";

import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import useInvoiceSummary from "./useInvoiceSummary";
import Styled from "./index.styles";

function InvoiceSummary() {
  const { invoiceNoOfSelectedPackingNo, customer, shippingId } =
    useInvoiceSummary();

  return (
    <Styled.container>
      <ListItem
        className="custom-list-item"
        type="withContentAlignRight"
        label="송장(QR)번호"
        value={invoiceNoOfSelectedPackingNo}
      />
      <ListItem
        className="custom-list-item"
        type="withContentAlignRight"
        label="고객명(코드명)"
        value={customer}
      />
      <ListItem
        className="custom-list-item"
        type="withContentAlignRight"
        label="출고요청번호"
        value={shippingId}
      />
    </Styled.container>
  );
}

export default memo(InvoiceSummary);
