import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const packingDetail = styled.div<{
  isEmpty: boolean;
  isVisibleScrollbar: boolean;
}>`
  padding: 24px 16px;
  margin-bottom: 80px;
  border-radius: 8px;
  background-color: ${COLOR.primaryBlue_10};

  > .tab-filter {
    margin-bottom: 24px;
  }

  .packing-detail-table {
    tr {
      ${({ isEmpty }) =>
        isEmpty &&
        css`
          height: 81.4px;
        `}
    }

    tbody {
      display: block;
      overflow: auto;
      max-height: 488.4px;

      ${({ isVisibleScrollbar }) =>
        isVisibleScrollbar &&
        css`
          &::-webkit-scrollbar {
            display: block;
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: ${COLOR.grayScale_400};
          }
          &::-webkit-scrollbar-track {
            background-color: ${COLOR.white};
          }
        `}

      td {
        word-break: break-all !important;
      }
    }
  }

  .tool-tip {
    position: static;
    cursor: pointer;

    .trigger + div {
    }
  }
`;

export default {
  packingDetail,
};
