import {
  TableComponent,
  TableDataListItem,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";

import { TabFilterComponent } from "../../headlessComponents/useTabFilter";
import { HandlerTableItem, MSWAPIType } from "./useHandlerList";
import Styled from "./index.styles";

export default function HandlerList({
  mswAPIType,
  handleMSWAPITypeChange,
  handlerTableList,
  TabFilter,
  Table,
}: {
  mswAPIType: MSWAPIType;
  handleMSWAPITypeChange: (v: MSWAPIType) => void;
  handlerTableList: TableDataListItem<HandlerTableItem>[];
  TabFilter: TabFilterComponent<MSWAPIType>;
  Table: TableComponent<HandlerTableItem>;
}) {
  return (
    <Styled.HandlerList>
      <TabFilter
        filterList={[
          {
            label: "Shipda",
            filter: "shipda",
          },
          {
            label: "Boful",
            filter: "boful",
          },
          {
            label: "Content",
            filter: "content",
          },
          {
            label: "shipdaAdmin",
            filter: "shipdaAdmin",
          },
          {
            label: "LocalPrinter",
            filter: "localPrinter",
          },
          {
            label: "NetworkPrinterForHanjinAndOverseas",
            filter: "networkPrinterForHanjinAndOverseas",
          },
          {
            label: "NetworkPrinterForCJ",
            filter: "networkPrinterForCJ",
          },
        ]}
        selectedFilter={mswAPIType}
        setFilter={handleMSWAPITypeChange}
      />

      <Table
        columnInfo={{
          handler: {
            label: "Handler",
            portion: 3,
          },
          active: {
            label: "Active",
          },
        }}
        dataList={handlerTableList}
      />
    </Styled.HandlerList>
  );
}
