import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { APP_TYPE } from "../../constants";

const defaultContainer = styled.div<{
  active: boolean;
}>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  font-size: 18px;
  font-weight: bold;

  > .label {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: ${COLOR.primaryBlue};

    ${mobile(css`
      font-size: 16px;
      line-height: 24px;
    `)}
  }
`;

const defaultImageWrapper = styled.div`
  width: 188px;

  ${mobile(css`
    width: 80px;
  `)}

  > img {
    width: 188px;

    ${mobile(css`
      width: 80px;
    `)}
  }
`;

const bofulContainer = styled.div<{
  active: boolean;
}>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #c2d8ff 0%, #5482d6 100%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  font-size: 18px;
  font-weight: bold;
  ${() => {
    if (APP_TYPE === "Boful") {
      return css`
        opacity: 0.7;
      `;
    }
  }}

  > img {
    width: 46px;
  }
`;

const bofulLoader = styled.div`
  margin-top: 24px;
  position: relative;
  width: 164px;
  min-height: 8px;

  @keyframes load {
    0% {
      left: 0;
      width: 0px;
    }
    50% {
      left: 82px;
      width: 82px;
    }
    100% {
      left: 164px;
      width: 0px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 162px; // 우측이 미세하게 어긋나서 2px 줄임
    min-height: 8px;
    background-color: ${COLOR.primaryBlue};
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 30px;
    min-height: 8px;
    background-color: ${COLOR.primaryBlue_40};
    animation: load 1.5s linear infinite;
  }
`;

export default {
  defaultContainer,
  defaultImageWrapper,

  bofulContainer,
  bofulLoader,
};
