import { useCallback, useMemo, useState } from "react";

import useNetworkPrint from "@sellernote/_shared/src/hooks/fulfillment/useNetworkPrint";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import Toast from "@sellernote/_sds-v1/src/components/Toast";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

/**
 * 전체 출력 시에만 바코드 버튼 동작을 위해서 packingIds를 전달
 */
export default function usePrintInvoice(packingIds?: number[]) {
  const [invoiceNo, setInvoiceNo] = useState("");

  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfGettingInvoicePrintingData,
    reset: resetGetInvoicePrintingData,
  } = PACKING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => {
      print(res);
    },
  });

  const {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    resetPrint,
  } = useNetworkPrint();

  const ToastOfPrintingInvoice = useMemo(
    () => (
      <>
        {isRequestPrintingComplete && (
          <Toast
            message={`송장번호 ${invoiceNo} 인쇄 요청이 완료되었습니다.`}
            status="success"
            needIcon
            reset={resetPrint}
          />
        )}
      </>
    ),
    [invoiceNo, isRequestPrintingComplete, resetPrint]
  );

  const printInvoice = useCallback(
    ({ packingIds, invoiceNo }: { packingIds: number[]; invoiceNo?: string }) =>
      () => {
        getInvoicePrintingData({ packingIds });
        invoiceNo && setInvoiceNo(invoiceNo);
      },
    [getInvoicePrintingData]
  );

  useScanActionBarcode({
    actionBarcode: "H_GET_INV_PRI",
    actionFn: resetGetInvoicePrintingData,
  });
  useScanActionBarcode({
    actionBarcode: "PRI_ALL_INV",
    actionFn: printInvoice({ packingIds: packingIds ?? [] }),
  });

  return {
    printInvoice,

    ResponseHandlerOfGettingInvoicePrintingData,

    ToastOfPrintingInvoice,
    LoadingOfPrintingInvoice: LoadingToNetworkPrint,
  };
}
