import { rest } from "msw";

import { getShipdaDevNewAPI } from "@sellernote/_shared/src/services/msw";
import newId from "@sellernote/_shared/src/utils/common/newId";

import { RealtimeQuotationFCL } from "../../../types/forwarding/quotation";
import { VESSEL_CARRIER } from "../../../types/forwarding/vesselSchedule";

import {
  GET_FCL_QUOTATION_RES,
  GET_FCL_QUOTATION_RES_ITEM,
} from "../../../api-interfaces/shipda-api/integratedRealtimeService";
import { ARTIFICIAL_DELAY_MS } from "../../../constants";

export const downloadAllAttachments = rest.get(
  getShipdaDevNewAPI("/attachments/send/email/103382"),
  (req, res, ctx) => res(ctx.status(200), ctx.delay(1000), ctx.json({}))
);

export const getUserBidList = rest.get(
  getShipdaDevNewAPI("/bid/v2/user"),
  (req, res, ctx) =>
    res(ctx.status(400), ctx.delay(1000), ctx.json({ code: 400 }))
);

// 회사소개 가이드북 요청, 보풀 사전신청
export const requestGuide = rest.post(
  getShipdaDevNewAPI("/customerCare/guide"),
  (req, res, ctx) => {
    const isNotName400Error = { code: 400, error: "is not name" }; // 이름을 정확하게 입력해주세요 문구 노출
    const default400Error = { code: 400, error: "" }; // 잘못된 요청입니다 문구 노출
    const defaultError = { code: 404, error: "" }; // 고객센터에 문의해주세요 문구 노출

    return res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(isNotName400Error)
    );
  }
);

export const getFCLQuotation = rest.get(
  getShipdaDevNewAPI("/searchEstimate/fcl/schedule"),
  (req, res, ctx) => {
    const createSampleItemForLogoTest = (
      carrier: VESSEL_CARRIER
    ): GET_FCL_QUOTATION_RES_ITEM => {
      return {
        quote: { cost: 50000 } as RealtimeQuotationFCL,
        schedule: {
          id: newId() as unknown as number,
          carrier,
          pol: "CNSHG",
          polPortName: "Shanghai Port",
          pod: "KRPUS",
          podPortName: "Korea Incheon Port",
          etd: "202304112300",
          eta: "202304140800",
          transitTimeInDays: 3,
          docCloseTime: "202304131200",
          cargoCloseTime: undefined,
          ts: false,
        },
      };
    };

    const data: GET_FCL_QUOTATION_RES = [
      {
        quote: { cost: 10000 } as RealtimeQuotationFCL,
        schedule: {
          id: newId() as unknown as number,
          carrier: "TAIYOUNG",
          pol: "CNSHG",
          polPortName: "Shanghai Port",
          pod: "KRPUS",
          podPortName: "Korea Incheon Port",
          etd: "202304112300",
          eta: "202304140800",
          transitTimeInDays: 3,
          docCloseTime: "202304131200",
          cargoCloseTime: undefined,
          ts: false,
        },
      },
      {
        quote: { cost: 7000 } as RealtimeQuotationFCL,
        schedule: {
          id: newId() as unknown as number,
          carrier: "COSCO",
          pol: "CNSHG",
          polPortName: "Shanghai Port",
          pod: "KRPUS",
          podPortName: "Korea Incheon Port",
          etd: "202304102300",
          eta: "202304140800",
          transitTimeInDays: 3,
          docCloseTime: "202304151200",
          cargoCloseTime: undefined,
          ts: true,
        },
      },
      {
        quote: { cost: 12000 } as RealtimeQuotationFCL,
        schedule: {
          id: newId() as unknown as number,
          carrier: "HAPAG-LLOYD",
          pol: "CNSHG",
          polPortName: "Shanghai Port",
          pod: "KRPUS",
          podPortName: "Korea Incheon Port",
          etd: "202304192300",
          eta: "202304140800",
          transitTimeInDays: 3,
          docCloseTime: "202304151200",
          cargoCloseTime: undefined,
          ts: true,
        },
      },
      createSampleItemForLogoTest("KMTC"),
      createSampleItemForLogoTest("SINOKOR"),
      createSampleItemForLogoTest("CK"),
      createSampleItemForLogoTest("ANL"),
      createSampleItemForLogoTest("CMACGM"),
      createSampleItemForLogoTest("COSCO"),
      createSampleItemForLogoTest("CNC"),
      createSampleItemForLogoTest("DONGJIN"),
      createSampleItemForLogoTest("DONGYOUNG"),
      createSampleItemForLogoTest("DOOWOO"),
      createSampleItemForLogoTest("ESL"),
      createSampleItemForLogoTest("EAS"),
      createSampleItemForLogoTest("EVERGREEN"),
      createSampleItemForLogoTest("HS"),
      createSampleItemForLogoTest("HEUNG-A"),
      createSampleItemForLogoTest("HAPAG-LLOYD"),
      createSampleItemForLogoTest("MAERSK"),
      createSampleItemForLogoTest("MSC"),
      createSampleItemForLogoTest("NAMSUNG"),
      createSampleItemForLogoTest("ONE"),
      createSampleItemForLogoTest("WEIDONG"),
      createSampleItemForLogoTest("YANGMING"),
      createSampleItemForLogoTest("ZIM"),
      createSampleItemForLogoTest("OOCL"),
      createSampleItemForLogoTest("PANOCEAN"),
      createSampleItemForLogoTest("PANCON"),
      createSampleItemForLogoTest("PIL"),
      createSampleItemForLogoTest("SEALAND"),
      createSampleItemForLogoTest("SITC"),
      createSampleItemForLogoTest("TAIYOUNG"),
      createSampleItemForLogoTest("TS"),
      createSampleItemForLogoTest("WANHAI"),
      createSampleItemForLogoTest("HMM"),
    ];

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);
