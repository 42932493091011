import { memo, useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import PrintInvoiceModal from "../PrintInvoiceModal";
import Styled from "./index.styles";

function PrintInvoice() {
  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );
  const [isVisiblePrintInvoiceModal, setIsVisiblePrintInvoiceModal] =
    useState(false);

  return (
    <>
      <Styled.printInvoiceButtonWrapper>
        <Button
          theme="secondary"
          size="block"
          label="송장(QR)수동출력"
          handleClick={() => setIsVisiblePrintInvoiceModal(true)}
          disabled={!isStatusOfScanningCompletedInvoice}
        />
      </Styled.printInvoiceButtonWrapper>

      <PrintInvoiceModal
        active={isVisiblePrintInvoiceModal}
        onClose={() => setIsVisiblePrintInvoiceModal(false)}
      />
    </>
  );
}

export default memo(PrintInvoice);
