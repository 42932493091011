import React, {
  ClipboardEventHandler,
  useEffect,
  useMemo,
  useRef,
} from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { IconType } from "@sellernote/_shared/src/componentsToMoveToV1/Icon/DATA";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { useIsTargetFocused } from "@sellernote/_shared/src/utils/common/hook";
import regEx from "@sellernote/_shared/src/utils/common/regEx";
import { getTrimmedValue } from "@sellernote/_shared/src/utils/common/string";
import { isWhiteSpace } from "@sellernote/_shared/src/utils/common/validation";

import Styled from "./index.styles";

type Value = string | number | undefined;

type ValueType =
  | "string"
  | "int"
  | "float"
  | "phoneNumber"
  | "email"
  | "password";

type InputType =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "number"
  | "search"
  | "password";

export type InputTextBorderType = "filled" | "outline" | "radius";

interface ButtonInfo {
  label: string;
  handleClick: () => void;
  disabled?: boolean;
}

interface InputTextCommonProps<T> {
  value: T;
  setValue?: (val: T) => void;
  borderType: InputTextBorderType;
  placeholder?: string;
  label?: React.ReactNode;
  labelGuide?: React.ReactNode;
  isRequired?: React.ReactNode;
  name?: string;
  width?: number;
  icon?: IconType;
  disabled?: boolean;
  handleFocusIn?: () => void;
  handleFocusOut?: () => void;
  clearCB?: () => void; // 내용 삭제 버튼을 눌렀을때 추가로 호출할 함수
  handleEnter?: () => void; // Enter를 입력했을때 추가로 호출할 함수
  isCompleted?: boolean; // 정상적으로 입력된 값임을 표시하고 싶을때 (체크표시됨)
  className?: string;
  errorMessage?: React.ReactNode;
  isValidated?: boolean;
  noWhiteSpace?: boolean; // 공백을 허용하지 않음
  button?: ButtonInfo;
  needFlag?: boolean;
  prefix?: string;
  autocomplete?: "off";
  visiblePostFix?: boolean; // value, disabled와 상관없이 항상 postFix를 보여주고 싶을 때 사용
  disabledToFocus?: boolean; // disabled 모양은 아니지만, 포커스싱만 막기 위해 사용
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
}

interface InputTextStringProps<T> {
  valueType: T extends string
    ? "password" | "email" | "string" | "phoneNumber"
    : never;
}

interface InputTextNumberProps<T> {
  valueType: T extends number ? "int" | "float" | "phoneNumber" : never;
}

function getInputType(valueType: ValueType): InputType {
  switch (valueType) {
    case "password": {
      return "password";
    }

    case "email": {
      return "email";
    }

    case "string": {
      return "text";
    }

    case "int":
    case "float": {
      return "number";
    }

    case "phoneNumber": {
      return "number";
    }
    default: {
      return "text";
    }
  }
}

function InputText<T extends Value>({
  borderType,
  valueType,
  placeholder,
  label,
  labelGuide,
  isRequired,
  name,
  value,
  setValue,
  width,
  icon,
  disabled,
  handleFocusIn,
  handleFocusOut,
  clearCB,
  handleEnter,
  isCompleted,
  className,
  errorMessage,
  isValidated,
  noWhiteSpace,
  button,
  needFlag,
  prefix,
  autocomplete,
  visiblePostFix,
  disabledToFocus,
  onPaste,
}: InputTextCommonProps<T> &
  (InputTextStringProps<T> | InputTextNumberProps<T>)) {
  const inputRef = useRef(null);

  const isInputRefFocused = useIsTargetFocused(inputRef);

  const postFixIcon = useMemo(getPostFixIcon, [
    icon,
    value,
    disabled,
    isCompleted,
    isValidated,
    errorMessage,
    isInputRefFocused,
    setValue,
    clearCB,
    visiblePostFix,
  ]);

  useEffect(() => {
    if (handleFocusIn && isInputRefFocused) {
      handleFocusIn();
    }
    if (handleFocusOut && !isInputRefFocused) {
      handleFocusOut();
    }
  }, [isInputRefFocused]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!setValue) {
      return;
    }

    let val = e.target.value;

    if (isWhiteSpace(val)) {
      return;
    }

    if (noWhiteSpace && typeof val === "string") {
      val = val.trim().replace(regEx.whiteSpace, "");
    }

    if (valueType === "int") {
      if (!val) {
        setValue(0 as T);
        return;
      }

      const valAsInt = parseInt(val);

      if ((val && isNaN(valAsInt)) || valAsInt <= 0) {
        setValue(value);
        return;
      }

      setValue(valAsInt as T);
      return;
    }

    if (valueType === "float") {
      if (!val) {
        setValue(0 as T);
        return;
      }

      const valAsFloat = parseFloat(val);
      if ((val && isNaN(valAsFloat)) || valAsFloat < 0) {
        setValue(value);
        return;
      }

      // 소수점 입력이 가능하도록 하기 위함
      if (valAsFloat === 0) {
        setValue("0" as T);
      } else {
        setValue(valAsFloat as T);
      }

      return;
    }

    setValue(val as T);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (
      (valueType === "int" || valueType === "float") &&
      ["e", "E", "+", "-"].includes(e.key)
    ) {
      e.preventDefault();
    }

    if (handleEnter && e.key === "Enter") {
      handleEnter();
    }
  }

  function getPostFixIcon() {
    const propsIcon = icon ? (
      <Icon
        type={icon}
        size={1}
        color={isInputRefFocused ? COLOR.primaryBlue : COLOR.grayScale_400}
      />
    ) : null;

    if (visiblePostFix) {
      return propsIcon;
    }

    if (errorMessage) {
      return <Icon type="warning" size={1} color={COLOR.pointWarning} />;
    }

    if (isCompleted || isValidated) {
      return <Icon type="check" size={1} color={COLOR.pointSuccess} />;
    }

    if (value) {
      if (!disabled) {
        return (
          <Icon
            type="circleFilledCancel"
            className="reset"
            size={1}
            color={COLOR.grayScale_400}
            onClick={() => {
              if (setValue) {
                if (valueType === "int" || valueType === "float") {
                  setValue(0 as T);
                  return;
                }

                setValue(undefined as T);
              }

              if (clearCB) {
                clearCB();
              }
            }}
          />
        );
      }
    } else {
      if (icon) {
        return propsIcon;
      }
    }

    return null;
  }

  return (
    <Styled.container
      className={`${className ? className : ""} input-text`}
      width={width}
      borderType={borderType}
      hasError={!!errorMessage}
      needFlag={needFlag}
      hasPrefix={!!prefix}
      disabled={disabled}
    >
      {label && (
        <label
          htmlFor={typeof label === "string" ? label : undefined}
          className="label"
        >
          {label}
          {isRequired && <em> (*필수)</em>}
          {labelGuide && <span className="label-guide"> {labelGuide}</span>}
        </label>
      )}

      <div className="input-wrapper">
        {needFlag && (
          <Styled.flagBlock>
            <img
              src="/assets/images/countryFlag/230-singapore.png"
              alt="flag"
            />
            <div className="number">+65</div>
            <Icon type="caretDown" color={COLOR.grayScale_700} size={1} />
          </Styled.flagBlock>
        )}

        {prefix && <Styled.prefix hasValue={!!value}>{prefix}</Styled.prefix>}

        <input
          name={name || (typeof label === "string" ? label : undefined) || ""}
          ref={inputRef}
          placeholder={placeholder || ""}
          value={value || ""}
          onChange={handleChange}
          disabled={disabled || disabledToFocus}
          type={getInputType(valueType)}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            setValue && setValue(getTrimmedValue(value));
          }}
          onPaste={onPaste}
          autoComplete={autocomplete}
        />

        {postFixIcon && (
          <Styled.postFix className="post-fix" isButton={Boolean(button)}>
            {postFixIcon}
          </Styled.postFix>
        )}

        {button && (
          <Styled.button
            disabled={
              button.disabled ? button.disabled : !(value && !errorMessage)
            }
            onClick={(e) => {
              e.preventDefault();
              button.handleClick();
            }}
          >
            {button.label}
          </Styled.button>
        )}
      </div>

      {errorMessage && <Styled.error>{errorMessage}</Styled.error>}
    </Styled.container>
  );
}

export default InputText;
