import styled, { css } from "styled-components";

import { mobile } from "../../styles/functions";

import { COLOR } from "../../stylesToMoveToV1/constants";
import { ViewType } from ".";

const MSWTool = styled.div<{ isOpenedTool: boolean; type: ViewType }>`
  position: fixed;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.grayScale_800};
  border-radius: 8px;

  ${({ isOpenedTool, type }) => {
    switch (type) {
      case "web":
        if (isOpenedTool) {
          return css`
            bottom: 60px;
            left: 0;
            padding: 16px 8px 0;
          `;
        } else {
          return css`
            bottom: 60px;
            left: 0;
            padding: 8px;
          `;
        }
      case "PDA":
        if (isOpenedTool) {
          return css`
            top: 0;
            left: 90px;
            flex-direction: column-reverse;
            padding: 0 8px 8px;
          `;
        } else {
          return css`
            top: 0;
            left: 90px;
            flex-direction: column-reverse;
            padding: 8px;
          `;
        }
    }
  }}

  .icon {
    cursor: pointer;
  }

  .tab-filter {
    margin-bottom: 8px;
  }
`;

const HandlerList = styled.div`
  background-color: ${COLOR.white};
  border-radius: 8px;
  padding: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 220px);

  .tab-filter {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${mobile(css`
    max-height: calc(100vh - 70px);
    max-width: calc(100vw - 110px);
  `)};

  > .table {
    td:last-child {
      padding: 0;
    }
  }
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .change-all-handlers {
    display: flex;
    align-items: center;

    .icon:last-child {
      margin-left: 8px;
    }
  }
`;

export default {
  MSWTool,
  HandlerList,
  ToggleButton,
};
