import React from "react";

import Icon from "../../componentsToMoveToV1/Icon";
import { COLOR } from "../../stylesToMoveToV1/constants";
import { ViewType } from ".";
import { MSWToolMode } from "./useChangeAllHandlers";
import Styled from "./index.styles";

export default function ToggleButtons({
  isOpenedTool,
  type,
  handleMSWToolToggle,
  mode,
  handleModeToggle,
  deactivateAllHandlers,
}: {
  isOpenedTool: boolean;
  type: ViewType;
  handleMSWToolToggle: () => void;
  mode: MSWToolMode;
  handleModeToggle: () => void;
  deactivateAllHandlers: () => void;
}) {
  return (
    <Styled.ToggleButton>
      <Icon
        type={
          isOpenedTool
            ? type === "PDA"
              ? "arrowUp"
              : "arrowLeft"
            : type === "PDA"
            ? "arrowDown"
            : "arrowRight"
        }
        size={2}
        color={COLOR.white}
        onClick={handleMSWToolToggle}
      />

      {isOpenedTool && (
        <div className="change-all-handlers">
          <Icon
            className="mode"
            type={mode === "normal" ? "toggleOff" : "toggleOn"}
            size={3}
            color={mode === "normal" ? COLOR.grayScale_700 : COLOR.pointWarning}
            onClick={handleModeToggle}
          />
          <Icon
            type="reset"
            color={COLOR.primaryBlue}
            onClick={deactivateAllHandlers}
          />
        </div>
      )}
    </Styled.ToggleButton>
  );
}
