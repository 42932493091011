import { useCallback, useMemo, useState } from "react";
import axios from "axios";
import iconv from "iconv-lite";

import { GET_INVOICE_PRINTING_DATA_RES } from "../../api-interfaces/boful-api/shipping";
import Loading from "../../componentsToMoveToV1/Loading";
import {
  NETWORK_PRINTER_FOR_CJ_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
} from "../../constants";

export default function useNetworkPrint(params?: { hidesLoading?: boolean }) {
  const [isLoadingOfNetworkPrint, setIsLoadingOfNetworkPrint] = useState(false);

  // 네트워크 프린터의 경우 응답값이 없기 떄문에 별도의 flag값을 둠
  const [isRequestPrintingComplete, setIsRequestPrintingComplete] =
    useState(false);

  const print = useCallback(async (res: GET_INVOICE_PRINTING_DATA_RES) => {
    setIsLoadingOfNetworkPrint(true);

    /**
     * 백엔드 요청
     * - 프린터는 네트워크 응답이 없으므로, 응답을 기다리지 않고(timeout: 1500) 다음 출력을 요청
     * - catch에서 아무것도 하지 않기
     */
    if (res.hanjin) {
      await axios
        .post(
          NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
          iconv.encode(res.hanjin, "euc-kr"),
          { timeout: 1500 }
        )
        .catch(() => {});
    }

    if (res.cj) {
      await axios
        .post(NETWORK_PRINTER_FOR_CJ_URL, iconv.encode(res.cj, "euc-kr"), {
          timeout: 1500,
        })
        .catch(() => {});
    }

    if (res.overseas) {
      await axios
        .post(
          NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
          iconv.encode(res.overseas, "euc-kr"),
          { timeout: 1500 }
        )
        .catch(() => {});
    }

    setIsLoadingOfNetworkPrint(false);
    setIsRequestPrintingComplete(true);
  }, []);

  const LoadingToNetworkPrint = useMemo(
    () => <Loading active={!params?.hidesLoading && isLoadingOfNetworkPrint} />,
    [isLoadingOfNetworkPrint, params?.hidesLoading]
  );

  const resetPrint = useCallback(() => {
    setIsRequestPrintingComplete(false);
  }, []);

  return {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    isLoadingOfNetworkPrint,
    resetPrint,
  };
}
