import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { FulfillmentParcelCompany } from "../../types/fulfillment/fulfillment";

/**
 * 긴 문자열을 축약
 */
function omitWithEllipsis({
  src,
  maxLength,
  ellipsis,
}: {
  src?: string;
  maxLength: number;
  ellipsis: string;
}): string {
  if (!src) {
    return "";
  }
  //string이 NFD(mac 방식)으로 있어 한글 length를 ㅁ,ㅏ으로 판별해 NFC로 변환해서 전달
  const NFCString = src.normalize("NFC");

  if (NFCString.length <= maxLength) {
    return NFCString;
  }

  const sliced = NFCString.slice(0, maxLength);

  return `${sliced} ${ellipsis}`;
}

function getTrimmedValue(str?: any) {
  if (!str || typeof str !== "string") {
    return str;
  }

  return str.trim();
}

/**
 * 문자의 앞부분에 있는 공백을 제거
 *
 * *앞쪽의 공백만 제거함에 유의
 */
function removeLeadingBlank(str: string) {
  if (!str || typeof str !== "string") return str;

  return str.replace(/^\s+/g, "");
}

function getLastStringAfterSplit({
  str,
  separator,
  limit,
}: {
  str: string;
  separator: string | RegExp;
  limit?: number;
}) {
  const splitStr = str.split(separator, limit);

  return splitStr[splitStr.length - 1];
}

/**
 * 값이 특정 색으로 조건부로 강조 되야하는 경우 사용
 * highlightColor의 default는 빨강
 */
function getHighlightedLabel({
  label,
  isHighlighted,
  highlightColor,
}: {
  label: string | number;
  isHighlighted: boolean;
  highlightColor?: string;
}) {
  if (!isHighlighted) return label;

  return (
    <span style={{ color: highlightColor || COLOR.pointWarning }}>{label}</span>
  );
}

/**
 * 하이픈이 추가된 송장번호를 반환
 * 택배사마다 하이픈 형식이 다름
 */
export function getInvoiceNoWithHyphen({
  invoiceNo,
  parcelCompany,
}: {
  invoiceNo: string;
  parcelCompany: FulfillmentParcelCompany | undefined;
}) {
  if (!invoiceNo || !parcelCompany) return "";

  switch (parcelCompany) {
    case "daesin": {
      return invoiceNo.replace(/^([0-9]{4})([0-9]{3})([0-9]{6})$/, "$1-$2-$3");
    }

    default: {
      return invoiceNo.replace(
        /(^02.{0}|^01.{1}|[0-9]{4})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
      );
    }
  }
}

/**
 * 하이픈이 추가된 휴대폰 번호를 반환
 */
function getPhoneNumberWithHyphen(phoneNumber: string) {
  if (!phoneNumber) return "";

  return phoneNumber
    .replace(/[^0-9]/, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

/**
 * 하이픈이 추가된 사업자등록번호를 번호를 반환
 */
function getBrnNumberWithHyphen(brnNumber: string) {
  if (!brnNumber) return "";

  return brnNumber
    .replace(/[^0-9]/, "")
    .replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
}

/**
 * 익명화 된 문자열로 변환
 */
function getAnonymizationString({
  source,
  anonymizationLength,
  anonymizationChar = "*",
}: {
  source: string | number;
  /**
   * 익명화 하고싶은 문가 길이 (맨 뒷글자에서부터 익명화 됨)
   */
  anonymizationLength: number | "all";
  anonymizationChar?: string;
}) {
  if (!source) return "";

  const sourceStr = source.toString();

  let targetLength = 0;
  const sourceLength = sourceStr.length;
  if (anonymizationLength === "all") {
    targetLength = sourceLength;
  } else {
    targetLength =
      anonymizationLength <= sourceLength ? anonymizationLength : sourceLength;
  }

  const rex = new RegExp(".{0," + targetLength + "}$");

  const replacingChar = new Array(targetLength).fill(0).reduce((a, _) => {
    return (a = a + anonymizationChar);
  }, "");

  return source.toString().replace(rex, replacingChar);
}

function removeComma(value: string | number) {
  return value.toString().replace(/,/gi, "");
}

function removeExceptNumbersAndDecimals(value: string | number) {
  return value.toString().replace(/[^-.0-9]/g, "");
}

export {
  omitWithEllipsis,
  getTrimmedValue,
  getLastStringAfterSplit,
  getHighlightedLabel,
  getPhoneNumberWithHyphen,
  getAnonymizationString,
  removeComma,
  removeLeadingBlank,
  removeExceptNumbersAndDecimals,
  getBrnNumberWithHyphen,
};
