// date 관련
// 매년 같은 공휴일
export const PUBLIC_HOLIDAY_LIST = [
  "01/01",
  "03/01",
  "05/01", // 근로자의 날
  "05/05",
  "06/06",
  "08/15",
  "10/03",
  "10/09",
  "12/25",
];

// 음력 공휴일, 음력 명절
export const LUNAR_PUBLIC_HOLIDAY_LIST = [
  "2023/05/27", // 석가탄신일
  // 설 연휴
  "2023/01/21",
  "2023/01/22",
  "2023/01/23",
  // 추석연휴
  "2023/09/28",
  "2023/09/29",
  "2023/09/30",
  // 2024년
  // 설 연휴
  "2024/02/09",
  "2024/02/10",
  "2024/02/11",
  "2024/05/15", // 석가탄신일
  // 추석연휴
  "2024/09/16",
  "2024/09/17",
  "2024/09/18",
];

// 대체 공휴일
export const SUBSTITUTE_HOLIDAY_LIST = [
  "2023/01/24", // 설 연휴 대체 휴일
  // 2024년
  "2024/02/11", // 설 연휴 대체 휴일
  "2024/04/10", // 22대 국회의원 선거
  "2024/05/06", // 어린이날 대체 휴일
];

export const TODAY = new Date();

export const TOMORROW = (() => {
  const tempDate = new Date();

  tempDate.setDate(TODAY.getDate() + 1);

  return tempDate;
})();
