import { datatype, date } from "faker";
import { rest } from "msw";

import { getBofulDevAPI } from "../../../services/msw";
import { IssueOptionList } from "../../../types/fulfillment/shipping";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { createCustomData, createData, DB } from "../../db";
import {
  E4000,
  E4002,
  E4008,
  E4009,
} from "../../error/4000_BOFUL_SHIPPING_ERROR";
import {
  START_PACKING_FOR_GROUP,
  START_PACKING_FOR_ISSUING_INVOICE,
  START_PICKING_FOR_CHANGE_PICKING_LOCATION,
} from "./data";

export const getShipping = rest.get(
  getBofulDevAPI("/shipping/worker/pda/pending"),
  (req, res, ctx) => {
    const data = createCustomData("풀필먼트_출고관리_의뢰확인_리스트_res");

    const filter: IssueOptionList =
      (req.url.searchParams.get("filter") as IssueOptionList) ?? "all";
    const perPage = Number(req.url.searchParams.get("perPage") ?? 10);
    const getFilteredList = (filter: IssueOptionList) => {
      switch (filter) {
        case "all":
          return DB.풀필먼트_출고관리_출고.findMany({ take: perPage });
        case "unIssue":
          return DB.풀필먼트_출고관리_출고.findMany({
            where: { isIssue: { equals: false } },
            take: perPage,
          });
        case "issue":
          return DB.풀필먼트_출고관리_출고.findMany({
            where: { isIssue: { equals: true } },
            take: perPage,
          });
      }
    };
    const filteredList = getFilteredList(filter);

    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ ...data, list: filteredList })
    );
  }
);

export const issueShippingInvoice = rest.patch(
  getBofulDevAPI("/shipping/worker/pda/force/print"),

  (req, res, ctx) => {
    const data = createData("풀필먼트_출고관리_출고", {
      isIssue: true,
    });

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const moveToMyTask = rest.patch(
  getBofulDevAPI("/shipping/worker/pda/task"),

  (req, res, ctx) => {
    const data = { result: "Update shipping managerId: 1" };

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const getCompletedPickingList = rest.get(
  getBofulDevAPI("/shipping/worker/pda/picking"),

  (req, res, ctx) => {
    const data = createCustomData("풀필먼트_출고관리_피킹완료_리스트_res");

    return res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(data));
  }
);

export const scanPickingInvoice = rest.patch(
  getBofulDevAPI(`/shipping/worker/picking/:invoiceNo`),

  (req, res, ctx) => {
    const invoiceNo = req.params.invoiceNo;

    switch (invoiceNo) {
      case "400":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json({ code: 400, error: "400에러" })
        );
      case "E4000":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4000)
        );
      case "E4002":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4002)
        );
      case "E4008":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4008)
        );
      case "E4009":
        return res(
          ctx.status(400),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(E4009)
        );

      case "change":
        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(START_PICKING_FOR_CHANGE_PICKING_LOCATION)
        );

      default:
        return res(
          ctx.status(200),
          ctx.delay(ARTIFICIAL_DELAY_MS),
          ctx.json(
            createData("풀필먼트_출고관리_출고", {
              pickerId: 1,
              wmsStatus: "picking",
              barCode: invoiceNo,
              skuId: 1,
              locationId: 2,
            })
          )
        );
    }
  }
);

export const startPickingScanning = rest.patch(
  getBofulDevAPI("/shipping/worker/picking/start/scanning/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.params.id),
          isStartScanning: true,
        })
      )
    )
);

export const changePickingLocation = rest.patch(
  getBofulDevAPI(`/shipping/worker/picking/location`),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        result: `ShippingItem Location Updated: 1`,
      })
    )
);

export const completePicking = rest.patch(
  getBofulDevAPI("/shipping/worker/picking/complete/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.params.id),
          endedPickingAt: date.past(),
        })
      )
    )
);

export const startPacking = rest.patch(
  getBofulDevAPI("/shipping/worker/packing/start/:invoiceNo"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        // createData("풀필먼트_출고관리_출고", {
        //   pickerId: 1,
        //   packerId: 1,
        //   wmsStatus: "packing",
        //   barCode: req.params.invoiceNo,
        //   skuId: 1,
        //   locationId: 2,
        //   user: DB.풀필먼트_user.create(),
        // })
        START_PACKING_FOR_ISSUING_INVOICE
      )
    )
);

export const AddNextPacking = rest.post(
  getBofulDevAPI("/shipping/worker/packing/:id"),

  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        invoiceNo: String(
          datatype.number({ min: 50000000000, max: 70000000000 })
        ),
        shippingId: Number(req.params.id),
        outerPackagesId: null,
        deletedAt: null,
        id: datatype.number({ min: 1, max: 100000 }),
      })
    );
  }
);

export const deleteLastPacking = rest.delete(
  getBofulDevAPI("/shipping/worker/packing/:invoiceNo"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ result: "delete PackingItem" })
    )
);

export const completePacking = rest.patch(
  getBofulDevAPI("/shipping/worker/packing/complete/:id"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(
        createData("풀필먼트_출고관리_출고", {
          id: Number(req.params.id),
        })
      )
    )
);

export const numberInvoice = rest.post(
  getBofulDevAPI("/parcel/invoice"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        totalCount: 30,
        successCount: 19,
        failedCount: 11,
        shippingIds: [1, 3, 2, 23],
      })
    );
  }
);

export const updateStatusWhenPrintingTransactionStatement = rest.post(
  getBofulDevAPI("/shipping/admin/new/print/statement"),
  (req, res, ctx) => {
    return res(
      // ctx.status(400),
      // ctx.delay(ARTIFICIAL_DELAY_MS),
      // ctx.json({ code: 400, error: "400에러" })
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS + 1500),
      ctx.json({
        total: 20,
        success: 18,
        fail: {
          ids: [2789, 5445],
          count: 2,
        },
      })
    );
  }
);

export const getInvoicePrintingData = rest.post(
  getBofulDevAPI("/parcel/print"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        cj: "cj",
        hanjin: "hanjin",
        overseas: "overseas",
      })
    );
  }
);

export const getInvoicePrintingDataForPacking = rest.post(
  getBofulDevAPI("/parcel/print/packing"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        cj: "cj",
        hanjin: "hanjin",
        overseas: "overseas",
      })
    );
  }
);

export const getShippingDetail = rest.get(
  getBofulDevAPI("/shipping/detail"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const getLocationDetails = rest.get(
  getBofulDevAPI("/location"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const changeShippingItemsLocations = rest.patch(
  getBofulDevAPI("/shipping/admin/item/locations"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const forceShipping = rest.post(
  getBofulDevAPI("/shipping/admin/new/force"),
  (req, res, ctx) =>
    res(
      ctx.status(400),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ code: 400, error: "400에러" })
    )
);

export const startPackingForGroup = rest.patch(
  getBofulDevAPI("/shipping/worker/packing/start/:invoiceNo"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json(START_PACKING_FOR_GROUP)
    )
);

export const getShippingTrackingInfoOfParcel = rest.get(
  getBofulDevAPI("/parcel/tracking"),

  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({
        level: 2,
        company: "cj",
        invoiceNo: "invoiceNo",
        address: "집",
        trackingDetails: [
          {
            remark: "비고",
            time: new Date(),
            name: "배송기사",
            phone: "전화번호",
            level: 2,
          },
        ],
      })
    )
);
