import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import PACKING_QUERY, {
  PACKING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import {
  OutSidePackageProviderValue,
  OutSidePackageValue,
} from "@sellernote/_shared/src/types/fulfillment/packing";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import {
  getPackingInfoOfPackingNo,
  providerOptionDict,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useOutSidePackage() {
  // 기본값: 상품에 등록된 외포장재가 쉽다, 고객사 둘 다 있을 경우 '고객사'를 기본값으로 표시
  const [provider, setProvider] =
    useState<InputSelectOption<OutSidePackageProviderValue>>();
  /**
   * 기본값: 없음, (선택)으로 표시
   * 포장 추가 시 기본값: 가장 작은 외포장재
   * '포장불필요'는 포장마감시 값 전달을 위해서 '포장불필요'값은 가지지만, 화면에는 표시하지 않음
   */
  const [outSidePackageItem, setOutSidePackageItem] =
    useState<InputSelectOption<OutSidePackageValue>>();
  /** Query 요청에 실패한 경우 바코드 모달을 닫고, 재요청하는 것을 막기 위해 사용 */
  const [disabledQueryByForce, setDisabledQueryByForce] = useState(false);

  const { userId: customerUserId } = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const outSidePackageProviderOfSelectedPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.OUT_SIDE_PACKAGE_PROVIDER_OF_SELECTED_PACKING_NO
  );
  const outSidePackageOfSelectedPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.OUT_SIDE_PACKAGE_OF_SELECTED_PACKING_NO
  );
  const isSelectableList = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_SELECTABLE_LIST
  );
  const [
    {
      selectedProvider,
      outSidePackageListOfShipda,
      outSidePackageListOfCustomer,
    },
    setOutSidePackage,
  ] = useRecoilState(FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE);
  const setPackingInfoList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );
  const packings = useRecoilValue(FULFILLMENT_PACKING_ATOMS.PACKINGS);

  const queryClient = useQueryClient();

  // customerUserId가 변경될 때 불러오기(패킹 시작 시)
  const {
    ResponseHandler: ResponseHandlerOfGettingOutSidePackageListOfCustomer,
  } = PACKING_QUERY.useGetOutsidePackageListOfCustomer({
    userId: customerUserId,
    enabled: !!customerUserId && !disabledQueryByForce,
  });

  // Provider 기본값 설정
  useEffect(() => {
    setProvider(selectedProvider);
  }, [selectedProvider]);

  // 포장탭을 변경했을 때, 외포장재 선택값을 연동시켜주기
  useEffect(() => {
    setProvider(outSidePackageProviderOfSelectedPackingNo);
    setOutSidePackageItem(outSidePackageOfSelectedPackingNo);
  }, [
    outSidePackageOfSelectedPackingNo,
    outSidePackageProviderOfSelectedPackingNo,
  ]);

  /**
   * 송장(QR)수동 출력 화면인 경우 외포장재 정보 설정
   * 고객사 외포장재리스트까지 가져온 이후에 설정 가능하므로, 여기서 설정
   * 송장 스캔 시 설정한 packings를 활용하여 외포장재 정보를 알아내서 설정
   */
  useEffect(() => {
    if (
      isStatusOfScanningCompletedInvoice &&
      isNotEmptyObjectOrArray(packings)
    ) {
      const getOutSidePackageData = (indexOfPackingInfo: number) => {
        const getOutSidePackage = ({
          outSidePackageList,
          outSidePackageId,
        }: {
          outSidePackageList: InputSelectOption<OutSidePackageValue>[];
          outSidePackageId: OutSidePackageValue;
        }) =>
          outSidePackageList.find(
            (outsidePackage) => outsidePackage.value === outSidePackageId
          );

        const outSidePackageOfShipda = getOutSidePackage({
          outSidePackageList: outSidePackageListOfShipda,
          outSidePackageId: packings[indexOfPackingInfo].outerPackagesId,
        });
        const outSidePackageOfCustomer = getOutSidePackage({
          outSidePackageList: outSidePackageListOfCustomer,
          outSidePackageId: packings[indexOfPackingInfo].outerPackagesId,
        });

        const provider: OutSidePackageProviderValue = outSidePackageOfShipda
          ? "shipda"
          : outSidePackageOfCustomer
          ? "customer"
          : "none";

        return {
          provider: providerOptionDict[provider],

          outSidePackage: outSidePackageOfShipda ??
            outSidePackageOfCustomer ?? { label: "포장불필요", value: "none" },
        };
      };

      // 화면의 기본값 설정
      const { provider, outSidePackage } = getOutSidePackageData(0);
      setProvider(provider);
      setOutSidePackageItem(outSidePackage);

      // 각 packingInfo에 외포장재값 설정
      setPackingInfoList((prevPackingInfoList) =>
        prevPackingInfoList.map((packingInfo, indexOfPackingInfo) => {
          const { provider, outSidePackage } =
            getOutSidePackageData(indexOfPackingInfo);

          return {
            ...packingInfo,
            outSidePackageProvider: provider,
            outSidePackage,
          };
        })
      );
    }
  }, [
    isStatusOfScanningCompletedInvoice,
    outSidePackageListOfCustomer,
    outSidePackageListOfShipda,
    packings,
    setOutSidePackage,
    setPackingInfoList,
  ]);

  const outSidePackageOptionList: InputSelectOption<OutSidePackageValue>[] =
    useMemo(
      () =>
        new Map([
          ["shipda", outSidePackageListOfShipda],
          ["customer", outSidePackageListOfCustomer],
          ["none", []],
        ]).get(selectedProvider?.value ?? "") ?? [],
      [
        selectedProvider?.value,
        outSidePackageListOfCustomer,
        outSidePackageListOfShipda,
      ]
    );

  const handleProviderSelect = useCallback(
    (selectedProvider: InputSelectOption<OutSidePackageProviderValue>) => {
      /** 화면에 보이는 Provider 변경 */
      setProvider(selectedProvider);

      /** OUT_SIDE_PACKAGE 변경 */
      setOutSidePackage((prev) => ({
        ...prev,
        selectedProvider,
      }));

      /** 마지막(현재 작업중) PACKING_INFO_LIST의 outSidePackageProvide를 변경 */
      setPackingInfoList((prevPackingInfoList) => {
        const lastPackingInfo = getPackingInfoOfPackingNo({
          packingInfoList: prevPackingInfoList,
          packingNo: "last",
        });

        return [
          ...prevPackingInfoList.slice(0, -1),
          {
            ...lastPackingInfo,
            outSidePackageProvider: selectedProvider,
          },
        ];
      });

      /** 상세 리스트에서 선택된 외포장재 초기화(화면에 보이는 값, PACKING_INFO_LIST의 outSidePackage) */
      if (selectedProvider.value === "none") {
        setOutSidePackageItem({ label: "포장불필요", value: "none" });

        setPackingInfoList((prevPackingInfoList) => {
          const lastPackingInfo = getPackingInfoOfPackingNo({
            packingInfoList: prevPackingInfoList,
            packingNo: "last",
          });

          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfo,
              outSidePackage: { label: "포장불필요", value: "none" },
            },
          ];
        });
      } else {
        setOutSidePackageItem(undefined);

        setPackingInfoList((prevPackingInfoList) => {
          const lastPackingInfo = getPackingInfoOfPackingNo({
            packingInfoList: prevPackingInfoList,
            packingNo: "last",
          });

          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfo,
              outSidePackage: { label: "", value: -1 },
            },
          ];
        });
      }
    },
    [setOutSidePackage, setPackingInfoList]
  );

  const handleOutSidePackageSelect = useCallback(
    (selectedOutSidePackage: InputSelectOption<OutSidePackageValue>) => {
      setOutSidePackageItem(selectedOutSidePackage);

      setPackingInfoList((prevPackingInfoList) => {
        const lastPackingInfo = getPackingInfoOfPackingNo({
          packingInfoList: prevPackingInfoList,
          packingNo: "last",
        });

        return [
          ...prevPackingInfoList.slice(0, prevPackingInfoList.length - 1),
          {
            ...lastPackingInfo,
            outSidePackage: selectedOutSidePackage,
          },
        ];
      });
    },
    [setPackingInfoList]
  );

  useScanActionBarcode({
    actionBarcode: "H_GET_CUS_OUT",
    actionFn: () => {
      queryClient.removeQueries(
        PACKING_QUERY_KEY_GEN.outSidePackageListOfCustomer()
      );
      setDisabledQueryByForce(true);
    },
  });

  return {
    isSelectableList,

    provider,
    handleProviderSelect,

    outSidePackageOptionList,
    outSidePackageItem,
    handleOutSidePackageSelect,

    ResponseHandlerOfGettingOutSidePackageListOfCustomer,
  };
}
