import { useCallback, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { validateCount } from "@sellernote/_shared/src/utils/common/validation";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import Styled from "./CountForScanning/index.styles";

export interface CountForScanning {
  skuId: number;
  currentQty: number | undefined;
  quantity: number;
  onCountAdd: (count: number) => void;
}

export default function useCountForScanning() {
  const [isVisibleDirectInputModal, setIsVisibleDirectInputModal] =
    useState(false);
  const [directInputForCount, setDirectInputForCount] = useState<number>();
  const [countForScanning, setCountForScanning] = useState<CountForScanning>();

  const handleDirectInputModalOpen = useCallback(() => {
    setIsVisibleDirectInputModal(true);
  }, []);

  const handleDirectInputModalClose = useCallback(() => {
    setDirectInputForCount(undefined);

    setIsVisibleDirectInputModal(false);
  }, []);

  const handleCountAdd = useCallback(() => {
    countForScanning?.onCountAdd(directInputForCount ?? 0);

    setDirectInputForCount(undefined);
    setIsVisibleDirectInputModal(false);
  }, [countForScanning, directInputForCount]);

  const remainingQuantity =
    (countForScanning?.quantity ?? 0) - (countForScanning?.currentQty ?? 0);

  const CountForScanningModal = useMemo(
    () => (
      <Modal
        active={isVisibleDirectInputModal}
        uiType="contentWithCustomBody"
        title={`${getFormattedSingleSkuId(countForScanning?.skuId)}(SKU ID)`}
        body={
          <Styled.directInputModalBody>
            <strong className="remaining-quantity">
              잔여수량: {remainingQuantity}
            </strong>

            <InputText
              borderType="outline"
              valueType="int"
              label="패킹 수량"
              placeholder="입력해주세요."
              value={directInputForCount}
              setValue={setDirectInputForCount}
              errorMessage={
                validateCount({
                  count: directInputForCount,
                  maxCount: remainingQuantity,
                }).message
              }
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              disabled={
                !validateCount({
                  count: directInputForCount,
                  maxCount: remainingQuantity,
                }).result
              }
              handleClick={handleCountAdd}
            />
          </Styled.directInputModalBody>
        }
        onClose={handleDirectInputModalClose}
      />
    ),
    [
      countForScanning?.skuId,
      directInputForCount,
      handleCountAdd,
      handleDirectInputModalClose,
      isVisibleDirectInputModal,
      remainingQuantity,
    ]
  );

  return {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  };
}
